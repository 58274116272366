import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import ResultMessage from "../components/resultMessage";
import { Box } from "@mui/material";
import Button from "../components/button";
import LoadingCircle from "../components/loading";
import Languages from "../languages/index";
import BuybyeService from "../services/buybye-service";
import cardError from "../assets/images/card_error.svg";
import cardAdded from "../assets/images/card_added.svg"; 
import { useHistory } from "react-router-dom";

// Success page used only for 3DS flow
export default function Success() {
  const { search } = useLocation();
  const history = useHistory();
  const values = queryString.parse(search);
  const returnMac = values.RETURNMAC;
  let id = "";
  let user_lang = "";
  let fromModal;
  const url = window.location.href;
  const splitURL = url.split("/");
  const indexCustomer = splitURL.indexOf("customer");
  if (indexCustomer !== -1 && indexCustomer < splitURL.length - 1) {
    id = splitURL[indexCustomer + 1].split("?")[0];
  }
  const indexLang = splitURL.indexOf("language");
  if (indexLang !== -1 && indexLang < splitURL.length - 1) {
    user_lang = splitURL[indexLang + 1].split("?")[0];
  } else {
    user_lang = "en";
  }
  const indexDefCard= splitURL.indexOf("card");
  if (indexDefCard !== -1 && indexDefCard < splitURL.length - 1) {
    fromModal = splitURL[indexDefCard + 1].split("?")[0] === "true";
  } else {
    fromModal = false;
  }
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);

  const createCard = async () => {
    setLoading(true);
    const response = await BuybyeService.createCustomerCard({}, id, returnMac);
    if (response.success) {
      setResult({
        image: cardAdded,
        title: Languages[user_lang].successAddCardTitle,
        text:
          Languages[user_lang].successAddCardText,
        btnText: fromModal
          ? Languages[user_lang].continueSettlement
          : Languages[user_lang].letShop,
        btnClick: () =>
          addEventListener("click", function () {
            window.ReactNativeWebView.postMessage("buttonClicked");
          }),
      });
    } else {
      let respErrorMessage = "";
      if (response.errorMessage.declinedCard) {
        respErrorMessage = Languages[user_lang].declinedCard;
      } else if (response.errorMessage.duplicateCard) {
        respErrorMessage = Languages[user_lang].duplicateCard;
      } else {
        respErrorMessage = response.errorMessage.message;
      }
      setResult({
        image: cardError,
        title: Languages[user_lang].errorTitle,
        text: respErrorMessage + "\n " + Languages[user_lang].tryAgainToSetup,
        btnText: Languages[user_lang].reviewCreditCardInfo,
        btnClick: () => {
          history.push(
            `/?id=${id}&userLang=${user_lang}&fromModal=${fromModal}`
          );
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    createCard();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        height: "100vh",
        padding: 0,
        margin: "0 auto",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <LoadingCircle size={100} />
        </Box>
      ) : (
        <>
          <ResultMessage content={result} lang={user_lang} />
          <Button className={"primary"} onClick={result.btnClick}>
            {result.btnText}
          </Button>
        </>
      )}
    </Box>
  );
}
