import axios from "axios";
import moment from "moment";
import RandExp from "randexp";
import config from "../config/config";
import { Buffer } from "buffer";
import utils from "../utils/validate";

const API_URL = config.AUTH_URL;
const LENGTH_SECRET = 80;

const fetch = async (method, url, data = undefined, h = {}) => {
  const headers = {
    secret: generateSecret().result,
    "reckon-origin": config.DNS_APP,
    ...h,
  };

  var options = {
    method: method,
    url: url,
    headers: headers,
    data,
    timeout: 30000,
  };

  let response = await axios(options)
    .then((r) => utils.successResult(true, r))
    .catch((r) => utils.successResult(false, { ...r, noResponse: true }));

  if (response.success) {
    response = response.result.data;
  }
  return response;
};


//data: {service || dns || idApp, data: {....}}
const encryptData = async (data) => {
  return await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "app/token",
    method: "GET",
  });
};

const successResult = (success, result) => {
  return { success, result };
};
const base64Encoded = (data) => {
  try {
    return successResult(
      true,
      new Buffer.from(data, "utf8").toString("base64")
    );
  } catch (e) {
    return successResult(false, "Error");
  }
};

const generateSecret = () => {
  function invertNumber(data) {
    try {
      if (data.length === 1) data = "0" + data;
      return data.split("").reverse().join("");
    } catch (e) {
      throw e;
    }
  }
  try {
    const date = moment().utc(false);
    let year = date.format("Y");
    let newYear = "";
    for (let i = 0; i < year.length; i++) {
      if (i % 2) newYear += year[i];
      else newYear += `[a-zA-Z]{${year[i]}}`;
    }
    const month = date.format("M");
    const day = date.format("D");
    const hour = invertNumber(date.format("H"));
    const minute = invertNumber(date.format("m"));
    const second = invertNumber(date.format("s"));
    let secret = new RandExp(
      `${newYear}:${hour}[a-zA-Z]{${month}}${minute}[a-zA-Z]{${day}}${second}`
    ).gen();
    secret += new RandExp(
      `[a-zA-Z]{${1}[a-zA-Z0-9]{${LENGTH_SECRET - secret.length - 1}`
    ).gen();
    return base64Encoded(secret);
  } catch (e) {
    return successResult(false, e);
  }
};


export default {
  fetch,
  generateSecret,
  encryptData,
};
