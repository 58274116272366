import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#3D2585",
    },
    secondary: {
      main: "#F3F2EA",
    },
    myMessage: {
      main: "#000000bf",
    },
    otherMessage: {
      main: "#00000069",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FCFCFC",
    },
    red: "#f53232d6",
  },
});
export default theme;
