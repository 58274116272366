import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom"; 
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { createBrowserHistory } from "history";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import PaymentData from "./pages/home";
import Success from "./pages/success";

const hist = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router history={hist}>
        <App>
          <Switch>
            <Route exact path="/" component={PaymentData} />
            <Route path="/success" component={Success} />
          </Switch>
        </App>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.querySelector("#root")
);
serviceWorker.unregister();


