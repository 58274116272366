import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import circularProgressClasses from "@mui/material/CircularProgress/circularProgressClasses";

const LoadingCircle = ({ size = 62 }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#B3B5BD",
          border: 0,
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#777986",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={4}
      />
    </Box>
  );
};

export default LoadingCircle;
