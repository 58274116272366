import React, { useEffect, useState, useRef, useCallback } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import ResultMessage from "../components/resultMessage";
import { Box } from "@mui/material";
import Button from "../components/button";
import LoadingCircle from "../components/loading";
import Languages from "../languages/index";
import BuybyeService from "../services/buybye-service";
import errorImg from "../assets/images/error.svg"; 
import cardError from "../assets/images/card_error.svg";
import cardAdded from "../assets/images/card_added.svg"; 

export default function PaymentData() {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const id = values.id;
  const user_lang = values.userLang || 'en';
  const fromModal = values.fromModal;
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [result, setResult] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const tokenizerRef = useRef(null);
  const locale = user_lang === "en" ? "en_EN" : "de_DE";

  const fetchHostedTokenizationUrl = useCallback(async () => {
    const genericError = {
      image: errorImg,
      title: Languages[user_lang].noResponseTitle,
      text: Languages[user_lang].noResponseText,
      btnText: Languages[user_lang].tryAgain,
      btnClick: fetchHostedTokenizationUrl,
    };
    setResult(null);
    setSubmitError(null);
    try {
      const response = await BuybyeService.getHostedTokenization(id);
      if (response.success) {
        const newTokenizer = new Tokenizer(
          response.result,
          "div-hosted-tokenization",
          { hideCardholderName: false, locale: locale }
        );
        newTokenizer
          .initialize()
          .then(() => {
            tokenizerRef.current = newTokenizer;
          })
          .catch((reason) => {
            setResult(genericError);
          });
      } else {
        setResult(genericError);
      }
    } catch (error) {
      setResult(genericError);
    } finally {
      setLoading(false);
    }
  }, []);

  const createCard = async(card)=> {
    card.default_card = fromModal === "true";
    card.language = user_lang;
    const response = await BuybyeService.createCustomerCard(card, id);
    const hostedTokenizationDiv = document.getElementById(
      "div-hosted-tokenization"
    );
    hostedTokenizationDiv.innerHTML = "";
    if (response.success){
      if (response.result && response.result.nextAction && response.result.nextAction.actionType === "REDIRECT") {
      window.location.href =
        response.result.nextAction.redirectData.redirectURL;
      } else {
        setResult({
          image: cardAdded,
          title: Languages[user_lang].successAddCardTitle,
          text: Languages[user_lang].successAddCardText,
          btnText: fromModal
            ? Languages[user_lang].continueSettlement
            : Languages[user_lang].letShop,
          btnClick: () =>
            addEventListener("click", function () {
              window.ReactNativeWebView.postMessage("buttonClicked");
            }),
        });
      }
    } else {
      let respErrorMessage = "";
      if (response.errorMessage.declinedCard) {
        respErrorMessage = Languages[user_lang].declinedCard;
      } else if (response.errorMessage.duplicateCard) {
        respErrorMessage = Languages[user_lang].duplicateCard;
      } else {
        respErrorMessage = response.errorMessage.message;
      }
        setResult({
          image: cardError,
          title: Languages[user_lang].errorTitle,
          text: respErrorMessage + "\n " + Languages[user_lang].tryAgainToSetup,
          btnText: Languages[user_lang].reviewCreditCardInfo,
          btnClick: fetchHostedTokenizationUrl,
        });
    }
    setLoadingBtn(false);
  }

  const submitForm = useCallback(async() => {
    if (tokenizerRef.current) {
      setLoadingBtn(true);
      tokenizerRef.current.submitTokenization().then(async (result) => {
        if (result.success) {
          await createCard({
            hostedTokenizationId: result.hostedTokenizationId,
          });
        } else {
          setSubmitError(result.error.message);
          setLoadingBtn(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchHostedTokenizationUrl();
  }, [fetchHostedTokenizationUrl]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        height: "100vh",
        padding: 0,
        margin: "0 auto",
      }}
    >
      <div id="div-hosted-tokenization"></div>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <LoadingCircle size={100} />
        </Box>
      ) : (
        <>
          {result ? (
            <>
              <ResultMessage content={result} lang={user_lang} />
              <Button className={"primary"} onClick={result.btnClick}>
                {result.btnText}
              </Button>
            </>
          ) : (
            <div className="btn-container">
              {submitError && <p className="error">{submitError}</p>}
              <Button
                className={"primary"}
                onClick={submitForm}
                disabled={loadingBtn}
                loading={loadingBtn}
                userLang={user_lang}
              >
                {Languages[user_lang].save}
              </Button>
            </div>
          )}
        </>
      )}
    </Box>
  );
}
