import React from "react";

export default function ResultMessage({ ...props }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "70vh",
        marginBottom: "4vh",
        marginTop: "40px",
      }}
    >
      <div style={{ maxWidth: "70%", display: "block", margin: "0 auto 4vh" }}>
        <img
          alt={props.content.title}
          src={props.content.image}
          className="responsive-img"
        />
      </div>
      <p className="title">{props.content.title}</p>
      <p className="text">{props.content.text}</p>
    </div>
  );
}
