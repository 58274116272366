import axios from "axios";
import AuthService from "./auth-service";
import config from "../config/config";
import utils from "../utils/validate";

const buybyeURL = () => {
  return config.BUYBYE_URL || "https://buybye.reckon.ai";
};

const getPaymentSystem = () => {
  return config.PAYMENT_SYSTEM;
};

const dns = config.DNS;

const getToken = async (dns) => {
  let res = await AuthService.encryptData({ dns });
  return res;
};

const fetch = async (
  method,
  url,
  data = undefined,
  h = {},
  timeout = 30000
) => {
  let authToken = await getToken(dns);
  if (!authToken.success) return authToken;

  const headers = {
    authtoken: authToken.result,
    ...h,
  };

  let options = {
    method: method,
    url: url,
    headers: headers,
    data,
    timeout: timeout,
  };

  let response = await axios(options)
    .then((r) => utils.successResult(true, r))
    .catch((r) => utils.successResult(false, { ...r, noResponse: true }));
  if (response.success) {
    response = response.result.data;
  }
  return response;
};

const getHostedTokenization = async (id) => {
  let result = await fetch(
    "GET",
    `${buybyeURL()}/app/hostedTokenization`,
    undefined,
    { clientId: id }
  );
  return result;
};

const createCustomerCard = async (card, id, returnMAC) => {
  let stepTwo = "";
  if (returnMAC) {
    stepTwo = `&step=2&returnMAC=${returnMAC}`;
  }
  let result = await fetch(
    "POST",
    `${buybyeURL()}/app/customer/card?payment=${getPaymentSystem()}${stepTwo}`,
    { card },
    { clientId: id }
  );
  return result;
};

export default {
  getToken,
  createCustomerCard,
  getPaymentSystem,
  getHostedTokenization,
};
