export default {
  save: "Save",
  tryAgain: "Try again",
  errorTitle: "Uh-Oh",
  noResponseTitle: "Something Went Wrong",
  noResponseText:
    "We couldn’t satisfy your request. Please try again. If the problem persists, try later.",
  tryAgainToSetup: "Try again to complete the setup process.",
  reviewCreditCardInfo: "Review Credit Card Info",
  successAddCardTitle: "Payment Added",
  successAddCardText:
    "Your payment method has been set up and is ready to be used on your account. Enjoy!",
  letShop: "Let’s shop",
  processing: "Processing",
  continueSettlement: "Continue to debt settlement",
  declinedCard: "Declined card.",
  duplicateCard: "Duplicated card.",
};
