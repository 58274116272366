import React from "react";
import classNames from "classnames";
import LoadingCircle from "./loading";
import Languages from "../languages/index";

const cx = classNames;

export default function Button({ children, className, disabled, loading, userLang, ...rest }) {
  const classNames = cx("button", className);
  return (
    <button className={classNames} {...rest} disabled={disabled}>
      {loading && (
        <>
          <LoadingCircle size={20} /> <div style={{marginRight: '5px'}}></div>
        </>
      )}
      {loading ? Languages[userLang].processing : children}
    </button>
  );
}
