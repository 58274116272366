export default {
  save: "Sichern",
  tryAgain: "Erneut versuchen",
  errorTitle: "Hoppla!",
  noResponseTitle: "Etwas ist schief gelaufen",
  noResponseText:
    "Wir können deine Anfrage gerade nicht nachkommen. Bitte versuch es erneut. Wenn das Problem weiterhin besteht, versuche es etwas später.",
  tryAgainToSetup:
    "Versuche es erneut, um den Einrichtungsvorgang abzuschließen.",
  reviewCreditCardInfo: "Kreditkarten Informationen überprüfen",
  successAddCardTitle: "Zahlungsart hinzugefügt",
  successAddCardText:
    "Deine Zahlungsart wurde akzeptiert und kann für dein Profil genutzt werden. Viel Spaß!",
  letShop: "Starte deinen Einkauf",
  processing: "In Bearbeitung",
  continueSettlement: "Weiter und offenen Saldo ausgleichen",
  declinedCard: "Karte abgelehnt.",
  duplicateCard: "Duplizierte Karte.",
};
